.content {
  max-width: 100vw;
  padding: 0;

  p {
    font-family: brandon-thin;
  }

  h1 {
    color: black;
    line-height: 3rem;
  }

  &.first {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 80vh;
    margin: 0 -2rem;
    overflow: hidden;
    background-image: url(../../../static/img/home/content-first.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    padding-left: 4rem;

    h1 {
      &:nth-child(1) {
        margin-left: -0.5rem;
      }
    }

    p {
      padding: 1rem 0;
      width: 50vw;
    }
  }

  &.second {
    background-color: #003399;
    margin: 0 -2rem;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);

    * {
      color: white;
      text-align: center;
    }

    &>* {
      padding: 4rem 1rem;
      border: 1px solid rgba(white, 0.5);
    }


    .left {
      h1 {
        font-size: 3rem;
        line-height: 5rem;
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-family: brandon;
      }

      a {
        padding-top: 2rem;
        display: flex;
        align-items: center;
        text-decoration: underline;
      }
    }
  }

  &.third {
    display: grid;
    justify-content: center;
    text-align: center;
    padding-top: 2rem;

    p {
      width: 50vw;
      margin: 0 auto;
    }

    a {
      text-decoration: underline;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: brandon-bold;

      span {
        padding-right: 5px;
      }
    }

    .templates {
      display: grid;
      justify-content: center;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
      padding: 6rem 0;

      .item {
        display: flex;
        flex-direction: column;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        img {
          max-width: 30vw;
        }

        h5 {
          margin-top: -1rem;
          color: black;
        }
      }
    }
  }

  &.fourth {
    margin: 0 -2rem;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);

    * {
      color: white;
      font-family: brandon;
    }

    .left {
      background-color: #0E3333;

      .up {
        padding: 2rem 1rem 1rem;
      }

      .down {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
        margin: 3rem 0;

        .steps {
          display: flex;
          flex-direction: column;
          border-top: 1px solid rgba(white, 0.5);
          padding-top: 1rem;
          margin: 10px;

          p {
            &:nth-child(2) {
              font-family: brandon-thin;
              font-size: 1rem;
            }
          }
        }
      }

      a {
        padding: 1rem 1rem 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;
      }
    }

    .right {
      background-color: whitesmoke;
      display: grid;
      align-items: center;

      img {
        padding-top: 3rem;
        width: 50vw;
      }
    }

  }

  &.fifth {
    background-color: rgb(255, 236, 212);
    margin: 0 -2rem;
    padding: 4rem 3rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .left {
      display: flex;
      flex-direction: column;
      margin-top: 5rem;

      h1 {
        margin: 1rem;
      }
    }

    .right {
      p {
        margin-top: 2rem;
        font-family: brandon;
        color: black;
        font-size: 1.25rem;
      }
    }

    a {
      padding: 4rem 0;
      color: black;
      display: flex;
      align-items: center;
      font-family: brandon-bold;
      text-decoration: underline;
    }
  }

  &.sixth {
    display: flex;
    flex-direction: column;

    * {
      color: black;
      font-family: brandon;
    }

    h1 {
      font-family: brandon-bold;
      padding: 4rem 0;
    }

    .faq {
      padding: 2rem 0;
      border-top: 1px solid black;
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      .question {
        font-size: 1.35rem;

        span {
          &:nth-child(1) {
            padding-right: 2rem;
          }
        }
      }
    }
  }

  &.seventh {
    background-color: #CCCCCC;
    margin: 0 -2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 7rem 2rem;

    .column {
      display: flex;
      flex-direction: column;
      color: black;

      h3 {
        color: rgb(75, 75, 75);
        margin-bottom: 2rem;
      }

      a {
        color: black;
        width: fit-content;

        &:hover {
          text-decoration: underline;
        }
      }

      label {
        display: flex;
        align-items: center;

        img {
          margin-right: 1rem;
          border-radius: 50%;
          width: 2rem;
        }
      }

      p {
        font-family: brandon;
        margin: 1rem 0;
        font-size: 1rem;
      }
    }
  }
}


@media screen and (min-width: 768px) {
  .content {
    &.first {

      p {
        width: 30vw;
      }
    }

    &.second {
      margin: 4rem -2rem 8rem -2rem;
    }

    &.fourth {
      margin: 10rem -2rem 8rem -2rem;
    }

    &.fifth {
      margin: 10rem -2rem 8rem -2rem;
    }

    &.sixth {
      margin: 10rem 0 8rem;
    }


  }
}


@media screen and (min-width: 1200px) {
  .content {
    &.first {
      margin: 0 -5rem;

      p {
        width: 30vw;
      }
    }

    &.second {
      padding: 4rem 3rem;
      margin: 7rem -5rem;
    }

    &.fourth {
      padding: 4rem 0rem;
      margin: 7rem -5rem;
    }

    &.fifth {
      margin: 7rem -5rem;
    }

    &.sixth {
      margin: 7rem 0;
    }

    &.seventh {
      padding: 7rem 5rem;
      margin: 7rem -5rem 0rem -5rem;
    }
  }
}